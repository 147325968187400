import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useDispatch } from "react-redux";
import { showDialog } from "../../../features/dialog/dialogSlice";
import Infopop from './../../molecules/dialogues/infoNoIcon';

const HelpInfoDailogButton = ({ open, title, info, handleInfoDailog }) => {

  return (
    <>
      <IconButton
        onClick={handleInfoDailog}
        size="small"
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          color: "primary",
        }}
      >
        <HelpOutlineIcon />
        <Infopop
                open={open}
                title={title}
                info={info}
                onClose={handleInfoDailog}
            />  
      </IconButton>
      
    </>
  );
};

export default HelpInfoDailogButton;
