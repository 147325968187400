import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clavaaTerms, PrivacyPolicy } from './info/terms';
import { showDialog } from '../../../../../features/dialog/dialogSlice';

const UseSignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Function to set and show Clavaa terms in the terms dialog
  const showClavaaTerms = () => {
    window.open('https://clavaa.com/terms-of-use', '_blank');
  };

  // Function to open Clavaa Privacy Policy in a new tab
  const showPrivacyPolicy = () => {
    window.open('https://clavaa.com/privacy', '_blank');
  };
  // Function to navigate to the sign-in page
  const navigateToSignIn = () => {
    navigate('/sign-in');
  };

  return {
    showClavaaTerms,
    showPrivacyPolicy,
    navigateToSignIn,
  };
};

export default UseSignUp;
