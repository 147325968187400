import { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth"; // Import Firebase Auth functions

const useRequestCodeDialog = (onSuccess) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    const auth = getAuth(); // Initialize Firebase Auth

    try {
      await sendPasswordResetEmail(auth, email);
      console.log("success!!")
      onSuccess(); // Call the success callback when email is sent successfully
    } catch (err) {
      console.log(err)
      setError(err.message); // Set error if something goes wrong
    }
  };

  return {
    email,
    error,
    setEmail,
    handleSubmit,
  };
};

export default useRequestCodeDialog;
