import React from "react";
import {
  Paper,
  Typography,
  Box,
} from "@mui/material";
import GeneralButton from "../../../../components/atoms/buttons/GeneralButton";
import StoreLocationsUI from "../molecules/storeLocationsUI";
import HelpInfoDailogButton from "../../../../components/atoms/dailog/helpInfoDailogButton";

const StoreProfileUI = ({
  handleLocationClick,
  setIsAddLocationDialogOpen,
  activeInfoDailog,
  handleInfoDailog,
  storeLocations,
}) => {
  return (
    <>
      <Paper
        elevation={3}
        sx={{
          maxWidth: 600,
          margin: "auto",
          mt: 4,
          p: 2,
          position: "relative",
        }}
      >
        <Typography variant="h4" gutterBottom textAlign="center">
          Store Locations
        </Typography>
        <Typography
          variant="body1"
          sx={{ mt: 2, textAlign: "center", width: "80%", margin: "auto" }}
        >
          By adding locations, once approved, they will appear in the consumer
          app under Discover, driving customers directly to your stores.
        </Typography>

        <StoreLocationsUI
          storeLocations={storeLocations}
          handleLocationClick={handleLocationClick}
        />

        <Box sx={{ alignContent: "center" }}>
          <GeneralButton
            label="Add Location to Discover"
            onClick={() => setIsAddLocationDialogOpen(true)}
            type="PlainText"
            center={true}
          />
        </Box>
        <HelpInfoDailogButton
          open={activeInfoDailog}
          title="About Adding Locations"
          info="By adding locations after we confirm it is a real location, we will display it to consumers in their app to drive them to your store. This verification process is crucial for maintaining the quality and trustworthiness of locations displayed to users."
          handleInfoDailog={handleInfoDailog}
        />
      </Paper>
    </>
  );
};

export default StoreProfileUI;
