import React from 'react';
import MiddleCardContent from '../../UI/organism/signUpCard';
import UseSignUp from '../../Functional/molecule/useSignUpCard';

const SignUpContainer = ({
    currentStep,
    handleSubmit,
    title,
    description, // Added description prop
    onBack,
    backArrow,
    disabled,
    children // Accepting children for dynamic form or other content
}) => {
  // Use the functions provided by UseSignUp
  const { showClavaaTerms, showPrivacyPolicy, navigateToSignIn } = UseSignUp();

  return (
    <MiddleCardContent
      showClavaaTerms={showClavaaTerms}
      showPrivacyPolicy={showPrivacyPolicy}
      navigateToSignIn={navigateToSignIn}
     
        currentStep={currentStep}
        handleSubmit={handleSubmit}
        title={title}
        description={description}
        onBack={onBack}
        backArrow={backArrow}
        disabled={disabled}
        children ={children}

    />
  );
};

export default SignUpContainer;
