// KYCPageStart.js
import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';
import BusinessMaster from './business/main';
import PersonalMaster from './personal/main';

const KYCPageStart = () => {
    return (
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={10} md={5}>
          <BusinessMaster />
        </Grid>
        <Grid item xs={12} sm={10} md={5}>
          <PersonalMaster />
        </Grid>
      </Grid>
    );
  };
  
  export default KYCPageStart;