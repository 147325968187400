import RegisterPage from '../../ui/organims/registerPage';
import RegisterLogic from './../../functional/organisms/registerLogic';

const RegisterPageComponent = () => {    

    const {
        activeInfoDailog,
        formVisible,
        error,
        accountInfo,
        registerSettings,
        masterRegister,
        showUpdateDialog,
        handleInfoDailog,
        handleUpdateModal,
        handleSubmitForm,
        handleSetupMasterRegisterSubmit,
        handleFormChange,
        handleFormVisible
    } = RegisterLogic();

    return (
        <RegisterPage
            activeInfoDailog={activeInfoDailog}
            formVisible={formVisible}
            masterRegister={masterRegister}
            registerSettings={registerSettings}
            accountInfo={accountInfo}
            error={error}
            showUpdateDialog={showUpdateDialog}
            handleInfoDailog={handleInfoDailog}
            handleUpdateModal={handleUpdateModal}
            handleSetupMasterRegisterSubmit={handleSetupMasterRegisterSubmit}
            handleFormVisible={handleFormVisible}
            handleSubmitForm={handleSubmitForm}
            handleFormChange={handleFormChange}
        />
    );
} 

export default RegisterPageComponent;