import { useState } from "react";
import useAddLocationDialog from "../molecules/useAddLocationDialog";
import useUpdateStorePhotoDialog from "../molecules/useUpdateStorePhotoDialog";
import useManagerComponent from "../organisms/useManagerComponent";
import { useDispatch } from "react-redux";
import useFullFetchFeedback from "../../../../functions/apiFetchers/fullPageLoaders";
import {
  addStoreLocation,
  deleteStoreLocation,
} from "../../../../features/stores/storesSlice";
import { useSelector } from "react-redux";
import { selectAllStoreLocations } from "../../../../features/stores/storesSelectors";


const ProfilePageLogic = () => {

  const dispatch = useDispatch();
  const [activeInfoDailog, setActiveInfoDailog] = useState(false);
  const [isLocationDialogOpen, setIsLocationDialogOpen] = useState(false);
  const [isAddLocationDialogOpen, setIsAddLocationDialogOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({});
  const fetchFeedback = useFullFetchFeedback();
  const storeLocations = useSelector(selectAllStoreLocations);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);


  const handleInfoDailog = (index) => {
    if (activeInfoDailog) {
      setActiveInfoDailog(false);
    } else {
      setActiveInfoDailog(true);
      
    }
    setActiveInfoDailog(!activeInfoDailog);
  };

  const handleConfirmDelete = async (locationId) => {
    setIsLocationDialogOpen(false);
    await fetchFeedback({
      endpoint: `merchant/locations/${locationId}/`,
      loaderMessage: "Deleting location...",
      successMessage: "Location Deleted successfully!",
      requestType: "DELETE",
      loaderType: "dialogueLoader",
      onSuccess: (responseData) => {
       
        dispatch(deleteStoreLocation(locationId));
      },
      onError: (responseData) => {
    
      },
    });
  };

  const createLocation = async (formData) => {
    console.log(formData)
    console.log("pressed");
    setIsAddLocationDialogOpen(false);
    await fetchFeedback({
      endpoint: `merchant/locations/`,
      data: { ...formData },
      loaderMessage: "Saving location details...",
      successMessage: "Location details saved successfully!",
      requestType: "POST",
      loaderType: "dialogueLoader",
      onSuccess: (responseData) => {
        dispatch(addStoreLocation(responseData));
      },
    });
  };

  const handleLocationClick = (location) => {
    setSelectedLocation(location);
    setIsLocationDialogOpen(true);
  };

  const {
    openUploadPhoto,
    updateOpenUploadPhoto,
    Apisubmission,
    setFormData,
    setSelectedPhoto,
    setUseDefaultPhoto,
    handleBackLocationForm,
    state,
  } = useManagerComponent({ createLocation });

  const { errors, fieldConfig, handleInputChange, handleAddClick } =
    useAddLocationDialog({
      setFormData,
      formData: state,
      onAdd: updateOpenUploadPhoto,
    });

  const { handlePhotoChange, handleUseDefaultPhotoChange, defaultStorePhoto } =
    useUpdateStorePhotoDialog({ setUseDefaultPhoto, setSelectedPhoto });

  return {
    state,
    activeInfoDailog,
    isLocationDialogOpen,
    isAddLocationDialogOpen,
    selectedLocation,
    openUploadPhoto,
    errors,
    fieldConfig,
    defaultStorePhoto,
    isConfirmDialogOpen,
    storeLocations,
    handleInfoDailog,
    setFormData,
    setSelectedPhoto,
    setUseDefaultPhoto,
    setIsAddLocationDialogOpen,
    handleConfirmDelete,
    handleLocationClick,
    Apisubmission,
    handleInputChange,
    handleAddClick,
    handlePhotoChange,
    handleUseDefaultPhotoChange,
    setIsConfirmDialogOpen,
    createLocation,
    handleBackLocationForm,
    setIsLocationDialogOpen
  };
};

export default ProfilePageLogic;
