import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Paper,
    Popper,
    Stack,
    Switch,
    Typography
} from '@mui/material';

// third-party
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import UpgradePlanCard from './UpgradePlanCard';
import useAuth from 'hooks/useAuth';
import User1 from 'assets/images/users/user-round.svg';

// assets
import { IconLogout, IconSearch, IconSettings, IconUser } from '@tabler/icons-react';
import useConfig from 'hooks/useConfig';
import AccountManager from '../status/main';
import { useAuthHelpers  } from '../../../../../../../functions/auth/signout'; // Update path if necessary

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const { borderRadius } = useConfig();
    const navigate = useNavigate();
    const { handleSignOut } = useAuthHelpers();
    const { user } = useAuth();
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleLogout = async () => {
        try {
            await handleSignOut();
        } catch (err) {
            console.error(err);
        }
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };


    const handleSettingClick = (event) => {
       
        handleClose(event);

     
            navigate("./settings");

    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    // Define your color palette here
    const colors = {
        apple: {
            background: '#f0f0f0', // Light gray
            border: '#d1d1d1',
            hoverBackground: '#e0e0e0',
            text: '#333333',
            primary: '#4285F4', // Google Blue
            primaryHover: '#357AE8',
            primaryLight: '#ffffff',
            chipWarning: '#FFA726', // Google warning color
        },
        google: {
            background: '#4285F4', // Google Blue
            border: '#357AE8',
            hoverBackground: '#3367D6',
            text: '#ffffff',
            primary: '#f0f0f0', // Light gray for contrast
            primaryHover: '#d1d1d1',
            primaryLight: '#ffffff',
            chipWarning: '#FFA726',
        },
    };

    // Choose which color palette to use
    // For simplicity, we'll use Apple palette. You can toggle between them based on your needs.
    const selectedColor = colors.apple;

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all 0.2s ease-in-out',
                    border: `1px solid ${selectedColor.border}`,
                    backgroundColor: selectedColor.background,
                    color: selectedColor.text,
                    '&:hover': {
                        borderColor: selectedColor.primary,
                        backgroundColor: selectedColor.hoverBackground,
                        color: selectedColor.primaryLight,
                        '& svg': {
                            stroke: selectedColor.primaryLight
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        src={User1}
                        sx={{
                            width: 32,
                            height: 32,
                            margin: '8px 0 8px 8px',
                            cursor: 'pointer'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                        alt="user-account"
                    />
                }
                label={<IconSettings stroke={1.5} size="24px" color={selectedColor.primary} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />

            <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 14]
                        }
                    }
                ]}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard
                                        border={false}
                                        elevation={16}
                                        content={false}
                                        boxShadow
                                        sx={{
                                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                                            borderRadius: '10px',
                                            backgroundColor: selectedColor.background,
                                            color: selectedColor.text
                                        }}
                                    >
                                        <Box sx={{ p: 2, pb: 0 }}>
                                            <Stack>
                                                <Stack direction="row" spacing={0.5} alignItems="center">
                                                    <Typography variant="h4">Good Morning,</Typography>
                                                    <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                        {user?.name}
                                                    </Typography>
                                                </Stack>
                                                <Typography variant="subtitle2">Project Admin</Typography>
                                            </Stack>
                                          <AccountManager useEnhancedStatusCard={true}/>
                                            <Divider />
                                        </Box>
                                        <PerfectScrollbar
                                            style={{
                                                height: '100%',
                                                maxHeight: 'calc(100vh - 250px)',
                                                overflowX: 'hidden'
                                            }}
                                        >
                                            <Box sx={{ p: 2, pt: 0 }}>
                                                <UpgradePlanCard />
                                                <Divider />
                                               
                                                <Divider />
                                                <List component="nav" sx={{ /* styles remain the same */ }}>
                <ListItemButton
                    sx={{ borderRadius: `${borderRadius}px`, color: selectedColor.text }}
                    selected={selectedIndex === 0}
                    onClick={(event) => handleSettingClick(event)}
                >
                    <ListItemIcon>
                        <IconSettings stroke={1.5} size="20px" color={selectedColor.primary} />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="body2" color={selectedColor.text}>
                                <FormattedMessage id="account-settings" />
                            </Typography>
                        }
                    />
                </ListItemButton>
                <ListItemButton
                    sx={{ borderRadius: `${borderRadius}px`, color: selectedColor.text }}
                    selected={selectedIndex === 4}
                    onClick={handleLogout}
                >
                    <ListItemIcon>
                        <IconLogout stroke={1.5} size="20px" color={selectedColor.primary} />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="body2" color={selectedColor.text}>
                                <FormattedMessage id="logout" />
                            </Typography>
                        }
                    />
                </ListItemButton>
            </List>
                                            </Box>
                                        </PerfectScrollbar>
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
