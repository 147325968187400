import React from "react";
import CustomTable from "../../../../components/molecules/tables/transactions";
import { Box, Typography, Divider } from "@mui/material";

// Function to format currency in "$00.00" format
const formatCurrency = (value) => {
  return `$${parseFloat(value).toFixed(2)}`;
};

const TransactionsTable = ({
  data,
  loading,
  rowsPerPage,
  totalRows,
  page,
  handlePageChange,
}) => {
  // Preprocess data to format currency fields
  const formattedData = data.map((item) => ({
    ...item,
    amount: formatCurrency(item.amount),
    cashbackEarned: formatCurrency(item.cashbackEarned),
    cashbackAmount: formatCurrency(item.cashbackAmount),
  }));

  // Define columns without changing the keys
  const columns = [
    { title: "Customer", key: "name" },
    { title: "Invoice ID", key: "id" },
    { title: "Date", key: "date" },
    { title: "Amount", key: "amount" },
    { title: "Cashback Earned", key: "cashbackEarned" },
    { title: "Cashback Redeemed", key: "cashbackAmount" },
    { title: "Status", key: "processing_status" },
    { title: "Register", key: "register" },
  ];

  return (
    <Box sx={{ p: { xs: 3, md: 6 } }}>
      {/* Updated Header with Larger Typography */}
      <Typography
        variant="h1"  // Increased header to variant "h1" for bigger text
        gutterBottom
        sx={{ fontWeight: "bold", color: "#000", mb: 1 }}
      >
        Transactions
      </Typography>
      <Typography
        variant="h4"  // Increased subtitle to variant "h4" for emphasis
        color="textSecondary"
        sx={{ mb: 3 }}
      >
        See and manage all of your transactions here.
      </Typography>
      <Divider sx={{ mb: { xs: 4, md: 6 } }} />
      <CustomTable
        title="Consumer Transactions"
        columns={columns}
        data={formattedData} // Pass formatted data here
        loading={loading}
        rowsPerPage={rowsPerPage}
        totalRows={totalRows}
        page={page}
        handlePageChange={handlePageChange}
        showPagination={true}
      />
    </Box>
  );
};

export default TransactionsTable;
