import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from "react-redux";

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Typography } from '@mui/material';
import { TrendingUp as TrendingUpIcon, Loyalty as LoyaltyIcon } from '@mui/icons-material';

// third party
import Chart from 'react-apexcharts';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// selectors
import { selectDashboardData } from "../../../../../features/dashboard/dashboardSelectors";

const TransactionCashbackChartCard = () => {
    const theme = useTheme();
    const { monthlyTransactions, monthlyCashback } = useSelector(selectDashboardData);

    // Check if data is available for the last twelve months
    const isDataEmpty = monthlyTransactions.every(value => value === 0) && monthlyCashback.every(value => value === 0);

    // Chart configuration
    const chartData = {
        height: 523,
        type: 'area',
        options: {
            chart: {
                id: 'transaction-cashback-chart',
                toolbar: { show: false },
                zoom: { enabled: false },
                sparkline: { enabled: true }
            },
            dataLabels: { enabled: false },
            stroke: { curve: 'smooth', width: 2 },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.5,
                    opacityTo: 0,
                    stops: [0, 80, 100]
                }
            },
            legend: { show: false },
            yaxis: { labels: { show: false } },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                title: { text: 'Last 12 Months' }
            },
            tooltip: {
                y: {
                    formatter: (val, opts) => {
                        return opts.seriesIndex === 0
                            ? `${val} Transactions`
                            : `$${val} Cashback`;
                    }
                }
            }
        },
        series: [
            { name: 'Total Transactions', data: monthlyTransactions },
            { name: 'Total Cashback ($)', data: monthlyCashback }
        ]
    };

    // Calculate growth, defaulting to 0% if no data is available
    const transactionChange = isDataEmpty ? 0 : calculateGrowth(monthlyTransactions);
    const cashbackChange = isDataEmpty ? 0 : calculateGrowth(monthlyCashback);

    // Function to calculate month-over-month growth
    const calculateGrowth = (data) => {
        const lastMonthIndex = data.length - 1;
        const previousMonthIndex = lastMonthIndex - 1;
        const current = data[lastMonthIndex];
        const previous = data[previousMonthIndex];
        if (previous === 0) return 0;
        return (((current - previous) / previous) * 100).toFixed(2);
    };

    return (
        <MainCard sx={{ '&>div': { p: 0, pb: '0px !important' } }}>
            <Box sx={{ p: 3 }}>
                <Grid container direction="column" spacing={3}>
                    {/* Header Section */}
                    <Grid item container spacing={1} alignItems="center">
                        <Grid item>
                            <Typography variant="h3">Transactions & Cashback</Typography>
                        </Grid>
                    </Grid>

                    {/* Subheading */}
                    <Grid item xs={12}>
                        <Typography sx={{ mt: -2.5, fontWeight: 400 }} color="inherit" variant="h5">
                            Monthly Transactions and Cashback Report
                        </Typography>
                    </Grid>

                    {/* Summary Statistics */}
                    <Grid item container alignItems="center" spacing={3}>
                        {/* Transactions Growth */}
                        <Grid item>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <Typography
                                        sx={{
                                            width: 40,
                                            height: 40,
                                            color: theme.palette.success.main,
                                            borderRadius: '12px',
                                            padding: 1,
                                            bgcolor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#e0f7fa'
                                        }}
                                    >
                                        <TrendingUpIcon stroke={2} />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4">+ {transactionChange}%</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Transactions Growth
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Cashback Growth */}
                        <Grid item>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <Typography
                                        sx={{
                                            width: 40,
                                            height: 40,
                                            color: theme.palette.warning.main,
                                            borderRadius: '12px',
                                            padding: 1,
                                            bgcolor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#fff3e0'
                                        }}
                                    >
                                        <TrendingUpIcon stroke={2} />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4">+ {cashbackChange}%</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Cashback Growth
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            {/* Conditionally render either the chart or motivational message */}
            {isDataEmpty ? (
 <Box
 sx={{
     height: chartData.height,
     display: 'flex',
     flexDirection: 'column',
     alignItems: 'center',
     justifyContent: 'center',
     p: 4,
     textAlign: 'center',
     backgroundColor: theme.palette.background.default,
     borderRadius: 2,
     boxShadow: 1,
 }}
>
 <LoyaltyIcon sx={{ fontSize: 72, color: theme.palette.primary.main, mb: 2 }} />
 <Typography variant="h5" sx={{ fontWeight: 700, fontSize: 26, color: theme.palette.text.primary, mb: 1 }}>
     Reward Your Customers
 </Typography>
 <Typography variant="body1" sx={{ maxWidth: 280, fontSize: 18, color: theme.palette.text.secondary, lineHeight: 1.6 }}>
     Boost loyalty and engagement with cashback rewards. They will just keep coming back.
 </Typography>
</Box>

           
            ) : (
                <Chart {...chartData} />
            )}
        </MainCard>
    );
};

TransactionCashbackChartCard.propTypes = {
    chartData: PropTypes.object
};

export default TransactionCashbackChartCard;
