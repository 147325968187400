import React, { useState, useEffect } from "react";
import SuccessDialog from "../../../../components/molecules/dialogues/success";
import ConfirmCodeDialog from "./ConfirmCodeDialog";
import RequestCodeDialog from "./RequestCodeDialog";
import UpdateCredentialDialog from "./UpdateCredentialDialog";

const CredentialManager = ({ type, open, handleClose }) => {
  const [currentStep, setCurrentStep] = useState("requestCode");

  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [profilePk, setProfilePk] = useState();
  console.log(type);

  useEffect(() => {
    if (!open) {
      // Reset the workflow if the dialog is closed
      setProfilePk("");
      setCurrentStep("requestCode");
      setShowSuccessDialog(false);
    }
  }, [open]);

  const handleEmailSuccess = () => {
 
console.log("here")
    setCurrentStep("success");
    setShowSuccessDialog(true);
  };

console.log(currentStep)


  const handleSuccessDialogClose = () => {
    setShowSuccessDialog(false);
    handleClose(); // Ultimately close everything and reset
  };

  const renderCurrentDialog = () => {
    switch (currentStep) {
      case "requestCode":
        return (
          <RequestCodeDialog
            open={open && currentStep === "requestCode"}
            onSuccess={handleEmailSuccess}
            handleClose={handleClose}
          />
        );
    
      case "success":
        return (
          <SuccessDialog
            open={showSuccessDialog}
            message={`An email has been sent if the current emal is registered `}
            onClose={handleSuccessDialogClose}
          />
        );
      default:
        return null;
    }
  };

  return <>{renderCurrentDialog()}</>;
};

export default CredentialManager;
