import React, { useState } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, IconButton, Typography, Tooltip, useMediaQuery } from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoIcon from '@mui/icons-material/Info';
import GeneralButton from '../../../../../components/atoms/buttons/GeneralButton';

const MiddleCardContent = ({
    handleSubmit,
    title,
    description,
    onBack,
    backArrow,
    disabled,
    showClavaaTerms,
    showPrivacyPolicy,
    navigateToSignIn,
    children
}) => {
    const isBelow700px = useMediaQuery("(max-width:700px)");
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
                p: isBelow700px ? 1 : 4,
                mx: "auto",
                width: "100%"
            }}
        >
            {/* Header Section */}
            <Box sx={{ width: '100%', textAlign: 'center', mb: 2, position: 'relative' }}>
                {backArrow && (
                    <IconButton
                        aria-label="back"
                        onClick={onBack}
                        sx={{
                            position: 'absolute',
                            left: 0,
                            top: '50%',
                            transform: 'translateY(-50%)',
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                )}
                <Typography 
                  variant="h2" 
                  component="div" 
                  sx={{ color: '#1EC674', fontWeight: 600, mb: 2 }}
                >
                  Clavaa
                </Typography>

                <Typography 
                  variant="h1" 
                  gutterBottom 
                  sx={{
                    color: '#000', fontWeight: 700, lineHeight: 1.2, mb: 2,
                  }}
                >
                  {title}
                </Typography>

                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 500, color: '#666', mb: 1, lineHeight: 1.4, textAlign: 'center',
                  }}
                >
                  {description}
                </Typography>
            </Box>

            {/* Render children for form fields or other content */}
            <Box sx={{ maxWidth: 500, width: '100%', mx: 'auto', textAlign: 'center', position: 'relative' }}>
                {children}

             
         
            </Box>

            {/* Terms and Conditions with Checkbox, aligned horizontally */}
            <Box sx={{ display: 'flex', alignItems: 'flex-start', mt: 2, textAlign: 'left', maxWidth: 500 }}>
                <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    color="primary"
                    sx={{ padding: 0}} // Ensures alignment with the text
                />
                <Typography variant="body2" sx={{ ml: 1 }}>
                    By checking this box, you agree to our{" "}
                    <a
                        onClick={showClavaaTerms}
                        style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            color: '#1EC674',
                        }}
                    >
                        Terms of Service
                    </a>{" "}
                    and{" "}
                    <a
                        onClick={showPrivacyPolicy}
                        style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            color: '#1EC674',
                        }}
                    >
                        Privacy Policy
                    </a>, as well as our partner Dwolla’s{" "}
                    <a
                        href="https://www.dwolla.com/legal/tos/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            color: '#1EC674',
                        }}
                    >
                        Terms of Service
                    </a>{" "}
                    and{" "}
                    <a
                        href="https://www.dwolla.com/legal/privacy/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            color: '#1EC674',
                        }}
                    >
                        Privacy Policy
                    </a>.
                </Typography>
            </Box>

            {/* Submit Button */}
            <GeneralButton
                label="Submit"
                type="contained"
                expandable={true}
                center={true}
                sx={{ mt: 3 }}
                onClick={handleSubmit}
                disabled={disabled || !isChecked}
            />
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
                    <Typography variant="body2" sx={{ color: '#666' }}>
                    Password Requirements
                    </Typography>
                    <Tooltip
                        title={
                            <Box sx={{ textAlign: 'left', p: 1 }}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 1, color: "white" }}>Password Requirements:</Typography>
                                <Typography variant="body2" component="ul" sx={{ p: 0, m: 0, listStyleType: 'disc', color: "white" }}>
                                    <li>At least 12 characters long</li>
                                    <li>Contains at least one letter</li>
                                    <li>Contains at least one number</li>
                                    <li>Contains at least one symbol</li>
                                </Typography>
                            </Box>
                        }
                        placement="right-start"
                        arrow
                    >
                        <IconButton aria-label="password requirements" sx={{ color: '#666' }}>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            

            <Box sx={{ width: '100%', mt: 2, display: 'flex', justifyContent: 'center' }}>
                <Typography variant="body2">
                    Already have an account?{' '}
                    <a
                        onClick={navigateToSignIn}
                        style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            color: '#1EC674',
                        }}
                    >
                        Sign In
                    </a>
                </Typography>
            </Box>
            
        </Grid>
    );
};

export default MiddleCardContent;
