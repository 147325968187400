// UserOptionsUI.js
import React from "react";
import { Typography, Link, Grid } from "@mui/material";

const UserOptionsUI = ({
  handleSignUp,
  handleResetPassword,
  handleResetUsername,
 
  credentialType,
  children,
}) => {
    const linkStyle = { cursor: "pointer", textDecoration: "none" };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      sx={{ mt: 1, mb: 2 }}
    >
     
      <Grid item xs={12} sm={12}>
        <Typography variant="body2">
          Forgot your{" "}
          <Link onClick={handleResetPassword} sx={{
                            cursor: 'pointer',
                            textDecoration: 'underline', // Underline for clickable text
                            color: '#1EC674', // Accent color for links
                        }}>
            Password?
          </Link>{" "}
         
        </Typography>
      </Grid>
      {/* Render CredentialManagerComponent passed as children if credentialType is set */}
      {credentialType && children}
    </Grid>
  );
};

export default UserOptionsUI;
