import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Box, Typography, Button, IconButton, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import UserOptions from "../molecules/UserOptions";
import AccountInfoPageUI from "../../ui/page/AccountInfoPageUI";
import useAccountInfo from "../../functionality/page/main";
import SignInPageContainer from "../../../../components/pageStructures/authPages/combined/pages/signInPageContainer";

const AccountInfoPage = () => {
  const { accountInfo, setAccountInfo, error, handleSubmit } = useAccountInfo();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    // Check if the user is signed in using Firebase authentication
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
        setShowModal(true); // Show modal if authenticated
      } else {
        setIsAuthenticated(false);
        setShowModal(false);
      }
    });
    return unsubscribe; // Cleanup the observer on component unmount
  }, [auth]);

  const handleContinue = () => {
    navigate("/dashboard");
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/sign-in"); // Navigate to sign-in page after logging out
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      {/* Authentication Modal */}
      <Dialog
        open={showModal}
        onClose={handleClose}
        aria-labelledby="account-info-dialog-title"
        aria-describedby="account-info-dialog-description"
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.2)", // Light transparent backdrop to show the page below
          },
        }}
      >
        <Box
          sx={{
            maxWidth: 400,
            width: "100%",
            bgcolor: "background.paper",
            borderRadius: 4,

            p: 4,
            position: "relative",
            textAlign: "center",
          }}
        >
         
          
          <Typography
            id="account-info-dialog-title"
            variant="h6"
            component="h2"
            sx={{ fontWeight: "600", mb: theme.spacing(2) }}
          >
            You’re Currently Signed In
          </Typography>
          
          <Typography
            id="account-info-dialog-description"
            variant="body1"
            sx={{ color: theme.palette.text.secondary, mb: theme.spacing(3) }}
          >
            You’re already signed into your account. Continue to your dashboard or switch to a different account.
          </Typography>
          
          <Button
            variant="contained"
            fullWidth
            onClick={handleContinue}
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              borderRadius: 3,
              textTransform: "none",
              fontWeight: "500",
              mb: theme.spacing(2),
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            Continue to Dashboard
          </Button>
          
          <Button
            variant="outlined"
            fullWidth
            onClick={handleSignOut}
            sx={{
              borderColor: theme.palette.grey[400],
              color: theme.palette.text.primary,
              borderRadius: 3,
              textTransform: "none",
              fontWeight: "500",
            }}
          >
            Use a Different Account
          </Button>
        </Box>
      </Dialog>

      {/* Page Content */}
      <SignInPageContainer handleSubmit={handleSubmit}>
        <AccountInfoPageUI
          accountInfo={accountInfo}
          setAccountInfo={setAccountInfo}
          error={error}
        >
          <UserOptions /> {/* Passing UserOptions as children */}
        </AccountInfoPageUI>
      </SignInPageContainer>
    </>
  );
};

export default AccountInfoPage;
