
import { useNavigate } from 'react-router-dom';

const UseSignIn = () => {
 
  const navigate = useNavigate();



  // Function to navigate to the sign-in page
  const navigateToSignUp = () => {
    navigate('/email'); // Adjust the route to the correct sign-in page route
  };

  // Return the functions to show terms and navigation function
  return {
   
    navigateToSignUp,
  };
};

export default UseSignIn;
