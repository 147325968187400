import React from 'react';
import { Box, Typography, Button, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const AccountStatusComponent = ({ onPress, status = 'active' }) => {
  const isActive = status.toLowerCase() === 'active';

  return (
    <Box
      sx={styles.container}
      role="group"
      aria-label={`Account is ${isActive ? 'active' : 'inactive'}`}
    >
      <Box sx={styles.statusBox}>
        {isActive ? (
          <CheckCircleIcon sx={{ ...styles.icon, color: '#4caf50' }} />
        ) : (
          <CancelIcon sx={{ ...styles.icon, color: '#b0bec5' }} />
        )}
        <Typography variant="body1" sx={styles.statusText}>
          {isActive ? 'Account Activated' : 'Account Inactive'}
        </Typography>
      </Box>

      <Button
        variant={isActive ? 'contained' : 'outlined'}
        color={isActive ? 'primary' : 'secondary'}
        onClick={onPress}
        sx={isActive ? styles.activeButton : styles.inactiveButton}
        aria-label={isActive ? 'See account details' : 'Activate account'}
      >
        {isActive ? 'See Details' : 'Activate Account'}
      </Button>
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 20px',
    backgroundColor: '#ffffff',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '500px',
  },
  statusBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  icon: {
    fontSize: '24px',
  },
  statusText: {
    fontSize: '1rem',
    fontWeight: 500,
    color: '#333333',
  },
  activeButton: {
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  inactiveButton: {
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '0.875rem',
    borderColor: '#b0bec5',
    color: '#b0bec5',
    '&:hover': {
      borderColor: '#90a4ae',
      backgroundColor: 'rgba(176, 190, 197, 0.04)',
    },
  },
};

export default AccountStatusComponent;
