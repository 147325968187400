import { Grid, Typography } from "@mui/material";
import GeneralInput from "../../../../../components/atoms/inputs/GeneralInput";

const EmailPageInputsUI = ({
  email,
  setEmail,
  password,
  setPassword,
  error,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <GeneralInput
          type={"text"}
          label="Email Address"
          name={"email"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!error.email}
          errorMessageAndhelp={error.email || ""}
        />
      </Grid>
      <Grid item xs={12}>
        <GeneralInput
          type={"password"}
          label="Password"
          name={"password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!error.password}
          errorMessageAndhelp={error.password || ""}
        />
      </Grid>
    </Grid>
  );
};

export default EmailPageInputsUI;
