// BankAccountCard.js

import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Avatar,
  Divider,
} from "@mui/material";
import { Lock as LockIcon, AccountBalance as BankIcon } from "@mui/icons-material";

const BankAccountCard = ({ bankData, hasBankAccount, handleAccountClick }) => {
  // Determine the logo source
  const isBase64 = bankData?.logo && bankData.logo.startsWith("data:image");
  const logoSrc = isBase64
    ? bankData.logo
    : bankData?.logo?.startsWith("http")
    ? bankData.logo
    : `data:image/png;base64,${bankData?.logo}`;

  const brandColor = "#1EC674"; // Your main brand color

  return (
    <Card
      variant="outlined"
      sx={{ borderRadius: 2, backgroundColor: "#FFFFFF", borderColor: "#E0E0E0", textAlign: "center" }}
    >
      <CardContent>
        {/* Header Section with Centered Text */}
        <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
          <BankIcon fontSize="large" sx={{ color: brandColor }} />
          <Typography variant="h5" sx={{ fontWeight: "bold", mt: 1 }}>
            Settlement Bank Account
          </Typography>
        </Box>
        
        {/* Description Text */}
        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "1.125rem", mb: 3 }}>
          This is the bank account where your sales revenue will be deposited.
        </Typography>
        
        <Divider />
        
        {/* Conditional Content */}
        {!hasBankAccount ? (
          <Box textAlign="center" mt={4} mb={2}>
            <Typography variant="h6" color="textSecondary" sx={{ mb: 2 }}>
              No bank account connected yet.
            </Typography>
            <Button
              variant="contained"
              onClick={handleAccountClick}
              sx={{
                backgroundColor: brandColor,
                color: "#FFFFFF",
                fontSize: "1rem",
                fontWeight: "bold",
                borderRadius: 8,
                px: 4,
                py: 1.5,
                "&:hover": {
                  backgroundColor: "#17a35a",
                },
              }}
            >
              Connect Bank Account
            </Button>
          </Box>
        ) : (
          <Box mt={3} textAlign="center">
            <Avatar
              src={logoSrc}
              alt={bankData.bankName}
              sx={{ width: 64, height: 64, mx: "auto" }}
            />
            <Box mt={2}>
              <Typography variant="h6" fontWeight={600}>
                {bankData.bankName}
              </Typography>
              <Typography variant="body1" color="textSecondary" sx={{ fontSize: "1rem" }}>
                {bankData.accountType} •••• {bankData.accountNumber.slice(-4)}
              </Typography>
            </Box>
            <Box mt={3}>
              <Button
                onClick={handleAccountClick}
                sx={{
                  color: brandColor,
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: "1rem",
                }}
              >
                Change Account
              </Button>
            </Box>
          </Box>
        )}
        
        <Divider sx={{ mt: 3 }} />
        
        {/* Security Information */}
        <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
          <LockIcon fontSize="small" color="action" />
          <Typography variant="body2" color="textSecondary" ml={0.5}>
            Securely connected via Plaid.
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BankAccountCard;
