
import MainLayout from "../../../../development/page/parts/layout/MainLayout/index";
import BankPageContainer from './../molecules/bankPageContainer';

const BankPage = () => {
    return (
        <MainLayout>
            <BankPageContainer/>
        </MainLayout>
    );
}    
export default BankPage;
