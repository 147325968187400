import StoreLocationDailogUI from "../organisms/storeLocationDailogUI";
import AddLocationUI from "../organisms/addLocationUI";
import StoreProfileUI from "../organisms/storeProfileUI";

const ProfilePageUI = ({
  fieldConfig,
  storeLocations,
  handleLocationClick,
  isAddLocationDialogOpen,
  setIsAddLocationDialogOpen,
  isLocationDialogOpen,
  setIsLocationDialogOpen,
  selectedLocation,
  handleConfirmDelete,
  activeInfoDailog,
  handleInfoDailog,
  openUploadPhoto,
  state,
  setFormData,
  Apisubmission,
  setSelectedPhoto,
  setUseDefaultPhoto,
  errors,
  handleAddClick,
  handleInputChange,
  defaultStorePhoto,
  handleUseDefaultPhotoChange,
  handlePhotoChange,
  handleBackLocationForm,
  setIsConfirmDialogOpen,
  isConfirmDialogOpen
}) => {
  return (
    <>
      <StoreProfileUI
        storeLocations={storeLocations}
        handleLocationClick={handleLocationClick}
        setIsAddLocationDialogOpen={() => setIsAddLocationDialogOpen(true)}
        activeInfoDailog={activeInfoDailog}
        handleInfoDailog={handleInfoDailog}
      />
      {isAddLocationDialogOpen && (
        <AddLocationUI
          Apisubmission={Apisubmission}
          fieldConfig={fieldConfig}
          onClose={() => setIsAddLocationDialogOpen(false)}
          openUploadPhoto={openUploadPhoto}
          defaultStorePhoto={defaultStorePhoto}
          setFormData={setFormData}
          state={state}
          setSelectedPhoto={setSelectedPhoto}
          setUseDefaultPhoto={setUseDefaultPhoto}
          errors={errors}
          handleAddClick={handleAddClick}
          handleInputChange={handleInputChange}
          handleUseDefaultPhotoChange={handleUseDefaultPhotoChange}
          handlePhotoChange={handlePhotoChange}
          handleBackLocationForm={handleBackLocationForm}
          
        />
      )}
      {isLocationDialogOpen && (
        <StoreLocationDailogUI
          isOpen={isLocationDialogOpen}
          onClose={() => setIsLocationDialogOpen(false)}
          location={selectedLocation}
          handleConfirmDelete={handleConfirmDelete}
          setIsConfirmDialogOpen={setIsConfirmDialogOpen}
          isConfirmDialogOpen={isConfirmDialogOpen}
        />
      )}
    </>
  );
};

export default ProfilePageUI;
