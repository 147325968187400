import React, { useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from "@mui/material";
import { useDwollaWeb, DwollaBalanceDisplay } from '@dwolla/react-drop-ins';

// Define the function to fetch the token from your Django backend
// Function to fetch the token from Django backend with additional request data
const dwollaAPIToken = async (req, additional) => {
    const tokenUrl = 'http://192.168.1.63:8000/api/tokenUrl/';
    console.log("Starting token fetch from:", tokenUrl);
    
    // Prepare the payload using the req and additional data
    const data = {
      action: req?.action,
      ...additional, // Spread additional properties if provided
    };
    
    if (req?.links) {
      data._links = req.links; // Add links if available
    }
  
    try {
      const response = await fetch(tokenUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
      
        body: JSON.stringify(data) // Send the payload
      });
  
      console.log("Fetch response received. Status:", response.status);
      if (response.ok) {
        const responseData = await response.json();
        console.log("Token fetched successfully. Response data:", responseData);
        return responseData.token; // Assumes response includes { token: "your-token-here" }
      } else {
        console.error("Error fetching token. Status code:", response.status, "Status text:", response.statusText);
        return null;
      }
    } catch (error) {
      console.error("Network or server error during token fetch:", error);
      return null;
    }
  };
  
  

// Configuration for Dwolla with token fetching function
const config = {
    environment: 'sandbox', // Use 'production' for live environment
    onError: () => console.log('Error loading Dwolla balance'),
    onSuccess: () => console.log('Dwolla balance loaded successfully'),
    token: (req) => Promise.resolve(dwollaAPIToken(req)), // Wrap in Promise.resolve
  };
  

const InfoModal = ({ open, onClose, customerId }) => {
  console.log("customerId:", customerId);
  
  const { ready, error } = useDwollaWeb(config);

  useEffect(() => {
    if (ready && customerId) {
      console.log('Dwolla balance component is ready to display for customer:', customerId);
    //   const response = dwollaAPIToken("hey")
    //   console.log(response)
    }
  }, [ready, customerId]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Transfer Information</DialogTitle>
      <DialogContent dividers>
      <Typography 
  variant="h5" 
  style={{ color: "#1a1a1a", fontSize: "1.5rem", fontWeight: "bold" }} 
  gutterBottom
>
  Fund Transfer Issue
</Typography>
<Typography 
  variant="body1" 
  style={{ color: "#333333", fontSize: "1rem", fontWeight: "normal" }} 
  gutterBottom
>
  If there’s an issue transferring funds to your bank, we’ll keep your money safe in your Clavaa account with our partner financial institutions. Once the issue is resolved, we’ll send the funds to your bank.
</Typography>





  {ready ? (
   <Box
   sx={{
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     minHeight: "200px", // Increase box height
     border: 1,
     borderRadius: 2,
     paddingBottom: 5,
     borderColor: "grey.300",
     bgcolor: "grey.100",
     textAlign: "center",
     mb: 2, // Adds some margin to the bottom
   }}
 >
   <DwollaBalanceDisplay customerid={customerId} />
 </Box>
 
  ) : (
    <Typography variant="body2" color="textSecondary">
      Loading balance...
    </Typography>
  )}

  {error && (
    <Typography variant="body2" color="error">
      Error loading balance information.
    </Typography>
  )}
</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoModal;
