// useAccountInfo.js
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import useFullFetchFeedback from "../../../../functions/apiFetchers/fullPageLoaders";
import { setStatus } from "../../../../features/user/userSlice";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import {
  activateLoader,
  deactivateLoader,
} from "../../../../features/loading/loadingSlice";

const useAccountInfo = () => {
  const [accountInfo, setAccountInfo] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const fetchFeedback = useFullFetchFeedback();
  const dispatch = useDispatch();
  const auth = getAuth();



  const convertSecondsToReadableTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    let readableTime = '';

    if (hours > 0) {
      readableTime += `${hours} hour${hours > 1 ? 's' : ''} `;
    }

    if (remainingMinutes > 0) {
      readableTime += `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''} `;
    }

    if (remainingSeconds > 0) {
      readableTime += `${remainingSeconds} second${remainingSeconds > 1 ? 's' : ''}`;
    }

    return readableTime.trim();

  };

  const handleErrorResponse = (data) => {
    console.log("data", data)
    console.log("data.detail")
   
    if (data.detail ) {
      console.log("throttled")
      const message = data.detail;
      const secondsMatch = message.match(/in (\d+) seconds/);

      if (secondsMatch) {
        const seconds = parseInt(secondsMatch[1], 10);
        const readableTime = convertSecondsToReadableTime(seconds);

        setError({
          username: '',
          password: `Too many attempts. Please try again in ${readableTime}.`,
        });
      } else {
        // Fallback message if parsing fails
        setError({
          username: '',
          password: 'Too many attempts. Please try again later.',
        });
      }
    } 
  };


  const handleSubmit = () => {
    dispatch(activateLoader({ "loaderType" :"pageLoader", "loaderMessage" :"Verifying Your Credentials" }));

    let isValid = true;
    let errors = { email: "", password: "" };
  
    // Basic email validation (checks format)
    const isEmailValid = (email) => {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };
  
    // Validate email
    if (!isEmailValid(accountInfo.email)) {
      errors.email = "Invalid email format";
      isValid = false;
    }
  
    // Validate password presence
    if (!accountInfo.password) {
      errors.password = "Password cannot be empty";
      isValid = false;
    }
  
    // If validation fails, set the errors and return early
    if (!isValid) {
      setError(errors);
      return;
    }
  
    // Firebase authentication attempt
    signInWithEmailAndPassword(auth, accountInfo.email, accountInfo.password)
      .then(() => {
        // Navigate to the dashboard on success
        dispatch(deactivateLoader("pageLoader"));
        navigate("/dashboard");
      })
      .catch((error) => {
        dispatch(deactivateLoader("pageLoader"));
        console.error("Firebase authentication error:", error);
        
        setError({
          email: "",
          password: "Incorrect credentials",
        });
      });
  };


  const handleSubmit2 = async () => {
    const errorValidation = [
      { field: "username", type: "content", required: true },
      { field: "password", type: "password", required: true },
    ];

    await fetchFeedback({
      endpoint: "merchant/sign-in/",
      data: { ...accountInfo },
      auth: false,
      requestType: "POST",
      validationRules: errorValidation,
      loaderMessage: "Signing in to Account...",

      onSuccess: (responseData) => {
        handleErrorResponse(responseData);
        console.log(responseData)
        localStorage.setItem("token", responseData.token);
        switch (responseData.status) {
          case "personalInfo":
            dispatch(setStatus("personalInfo"));
            navigate("/personal-info");
            break;
          case "businessInfo":
            dispatch(setStatus("businessInfo"));
            navigate("/business-info");
            break;
          case "verify":
            dispatch(setStatus("verify"));
            navigate("/status");
            break;
            
          case "complete":
            dispatch(setStatus("complete"));
            navigate("/dashboard");
            break;
          default:
            break;
        }
      },
      onError: (error) => {
        setError(error);
        
      },
    });
  };

  return {
    accountInfo,
    setAccountInfo,
    error,
    handleSubmit,
  };
};

export default useAccountInfo;
