import { List, Typography } from "@mui/material";
import LocationDetailUI from "../atoms/locationDetailUI";

const StoreLocationsUI = ({ storeLocations, handleLocationClick }) => {
  return storeLocations && storeLocations.length > 0 ? (
    <List>
      {storeLocations.map((location, index) => (
        <LocationDetailUI
          location={location}
          handleLocationClick={handleLocationClick}
        />
      ))}
    </List>
  ) : (
    <Typography
      variant="body1"
      textAlign="center"
      color="textSecondary"
      sx={{ my: 4 }}
    >
      Your store locations will appear here. Add a new location to start
      showcasing your stores.
    </Typography>
  );
};

export default StoreLocationsUI;
