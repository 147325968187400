// src/EmailPage.js
import React from 'react';
import useEmail from '../../functional/page/useEmail';
import SignUpPageContainer from '../../../../../components/pageStructures/authPages/combined/pages/signUpPageContainer';
import EmailPageInputsUI from '../../ui/organisms/emailPageInputsUI';
import DialogCombined from '../organism/dialog';

const EmailPage = () => {
  const {
    handleSubmit,
    title,
    description,
    currentStep,
    email,
    setEmail,
    password,
    setPassword,
    profilePk,
    setProfilePk,
    error,
    isCodeDialogOpen,
    handleCloseCodeDialog,
    navigate,
  } = useEmail();

  return (
    <>
      <SignUpPageContainer
        currentStep={currentStep}
        handleSubmit={handleSubmit}
        title={title}
        description={description}
      >
        <EmailPageInputsUI
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          error={error}
        />
      </SignUpPageContainer>

      {/* Code Dialog */}
      <DialogCombined
        email={email}
        password={password}
        open={isCodeDialogOpen}
        onClose={handleCloseCodeDialog}
        profilePk={profilePk}
        setProfilePk={setProfilePk}
        navigate={navigate}
    
        handleCloseCodeDialog={handleCloseCodeDialog}
      />
    </>
  );
};

export default EmailPage;
