import React from 'react';
import SignUpContainer from '../organism/signUp';
import AuthPage from '../../UI/pages/main';


const SignUpPageContainer = ({
  currentStep,
  handleSubmit,
  title = "Sign Up",  // Default title
  description = "Create your account with Clavaa",  // Default description
  onBack,
  backArrow,
  disabled,
  children
}) => {
  return (
    <AuthPage title={"Sign Up to Clavaa"}>

      <SignUpContainer
        currentStep={currentStep}
        handleSubmit={handleSubmit}
        title={title}
        description={description}
        onBack={onBack}
        backArrow={backArrow}
        disabled={disabled}
      >
      
        {children}
      </SignUpContainer>
    </AuthPage>
  );
};

export default SignUpPageContainer;
