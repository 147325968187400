// useUserOptions.js
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const useUserOptions = () => {
  const [credentialType, setCredentialType] = useState(null);
  const navigate = useNavigate();



  const handleSignUp = () => {
    navigate("/email");
  };

  const handleResetPassword = (event) => {
    event.preventDefault();
    setCredentialType("password");
  };

  

  const handleCloseDialog = () => {
    setCredentialType(null);
  };

  return {
    credentialType,
  
    handleSignUp,
    handleResetPassword,
  
    handleCloseDialog,
  };
};

export default useUserOptions;
