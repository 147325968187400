// BankingPage.js

import React from "react";
import { Box, Grid, Typography, Divider } from "@mui/material";
import BankLogic from "../../functional/organisms/bank";
import BankAccountCard from "./BankAccountCard";
import PendingFundsCard from "./PendingFundsCard";
import LoadingModal from "./LoadingModal";
import SuccessModal from "./SuccessModal";

const BankingPage = () => {
  const {
    bankData,
    hasBankAccount,
    pendingBalance,
    openModal,
    success,
    onClose,
    handleAccountClick,
  } = BankLogic();

  return (
    <Box sx={{ p: { xs: 3, md: 6 } }}>
      {/* Updated Header with Larger Typography */}
      <Typography
        variant="h1"  // Increased header to variant "h1" for bigger text
        gutterBottom
        sx={{ fontWeight: "bold", color: "#000", mb: 1 }}
      >
        Banking
      </Typography>
      <Typography
        variant="h4"  // Increased subtitle to variant "h4" for emphasis
        color="textSecondary"
        sx={{ mb: 3 }}
      >
        Manage your bank accounts and pending funds.
      </Typography>
      <Divider sx={{ mb: { xs: 4, md: 6 } }} />

      {/* Improved Page Structure */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <BankAccountCard
            bankData={bankData}
            hasBankAccount={hasBankAccount}
            handleAccountClick={handleAccountClick}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PendingFundsCard pendingBalance={pendingBalance} />
        </Grid>
      </Grid>

      {/* Modals */}
      <LoadingModal open={openModal} />
      <SuccessModal open={success} onClose={onClose} />
    </Box>
  );
};

export default BankingPage;
