// CombinedFunctionality.js
import React, { useState, useRef } from 'react';
import { getAuth, createUserWithEmailAndPassword, setPersistence, browserLocalPersistence } from "firebase/auth";
import useFullFetchFeedback from '../../../../../functions/apiFetchers/fullPageLoaders';
import { setStatus } from "../../../../../features/user/userSlice";
import { useDispatch } from "react-redux";
import { setHasFetched } from '../../../../../features/initialData/initialDataSlice';
import { showDialog } from "../../../../../features/dialog/dialogSlice";
function CombinedFunctionality(props) {
  const { email, password, profilePk, setProfilePk, navigate, handleCloseCodeDialog } = props;
  const codeLength = 5;
  const [codeValues, setCodeValues] = useState(Array(codeLength).fill(''));
  const inputRefs = useRef([]);
  const [isLoadingCode, setIsLoadingCode] = useState(false);
  const [isLoadingAccount, setIsLoadingAccount] = useState(false);
  const [isResendingCode, setIsResendingCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const dispatch = useDispatch();

  const fetchFeedback = useFullFetchFeedback();

  // Handle input change and move to the next field
  const handleChange = (index, value) => {
    if (!/^\d?$/.test(value)) return; // Ensure only numbers

    const newCodeValues = [...codeValues];
    newCodeValues[index] = value;
    setCodeValues(newCodeValues);

    if (value && index < codeLength - 1) {
      inputRefs.current[index + 1]?.focus();
    }

    if (newCodeValues.every((char) => char !== '')) {
      confirmCode(newCodeValues.join(''));
    }
  };

  // Handle backspace key for navigating between fields
  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && !codeValues[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  // Reset code input fields
  const resetCode = () => {
    setCodeValues(Array(codeLength).fill(''));
    inputRefs.current.forEach((ref) => {
      if (ref) {
        ref.value = '';
      }
    });
    inputRefs.current[0]?.focus();
  };
  
  const confirmCode = async (code) => {
    setErrorMessage('');
    setIsLoadingCode(true);

    try {
      // Submit the code to `email/confirm/` endpoint
      await fetchFeedback({
        endpoint: "email/confirm/",
        requestType: "POST",
        auth: false,
        data: { profile_pk: profilePk, otp: code },
        onSuccess: async () => {
          setIsLoadingAccount(true);
          setIsLoadingCode(false);
          try {
            const auth = getAuth();
            await setPersistence(auth, browserLocalPersistence);
            const userCredential = await createUserWithEmailAndPassword(
              auth,
              email,
              password
            );
            const firebaseUser = userCredential.user;
            // Link Firebase user with backend user
            await fetchFeedback({
              endpoint: "merchant/account_info/",
              requestType: "POST",
              data: {
                email: firebaseUser.email,
                uid: firebaseUser.uid,
                profile_pk: profilePk,
              },
              onSuccess: () => {
                // Show success component
                setIsLoadingAccount(false);
                setIsSuccess(true);
              },
              onError: (err) => {
                console.log(err);
                setIsLoadingAccount(false);
              },
            });
          } catch (firebaseError) {
            console.log(firebaseError);
            setIsLoadingAccount(false);
          }
        },
        onError: (err) => {
          setErrorMessage(err.otp || 'An error occurred. Please try again.');
          resetCode();
          setIsLoadingCode(false);
          setIsLoadingAccount(false);
        },
      });
    } catch (err) {
      console.log(err);
      setErrorMessage(err.otp || 'An error occurred. Please try again.');
      resetCode();
      setIsLoadingCode(false);
      setIsLoadingAccount(false);
    }
  };

  // Resend verification code
  const resendCode = async () => {
    setIsResendingCode(true);
    setErrorMessage('');

    await fetchFeedback({
      endpoint: 'merchant/email/',
      requestType: 'POST',
      data: { email },
      onSuccess: (responseData) => {
        setIsResendingCode(false);
        setProfilePk(responseData.profile_pk);
      },
      onError: (err) => {
        setIsResendingCode(false);
      },
    });
  };

  const handleGoToDashboard = () => {
    dispatch(setHasFetched(true));
    dispatch(setStatus("businessInfo"));
    navigate("/dashboard");
    dispatch(showDialog({
      dialogType: 'guide'
    }));
  };

  return {
    isLoadingCode,
    isLoadingAccount,
    isResendingCode,
    isSuccess,
    errorMessage,
    codeValues,
    inputRefs,
    handleChange,
    handleKeyDown,
    resendCode,
    confirmCode,
    resetCode,
    handleGoToDashboard,
  };
}

export default CombinedFunctionality;
