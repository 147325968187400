import { useState } from "react";
import validateLocationDetails from "../helpers/validate";
// import { locationFields} from './../constants/locationFields';

const useAddLocationDialog = ({ setFormData, formData, onAdd }) => {
  const storeTypes = [
    { label: "Other", value: "Other" },
    { label: "Retail", value: "Retail" },
    { label: "Wholesale", value: "Wholesale" },
    { label: "Cafe", value: "Cafe" },
    { label: "Boutique", value: "Boutique" },
    { label: "Bookstore", value: "Bookstore" },
  ];

  const [errors, setErrors] = useState({});
  const fieldConfig = [
    {
      name: "name",
      label: "Store Name",
      type: "text",
      grid: { xs: 12, sm: 6 },
    },
    {
      name: "address",
      label: "Address",
      type: "address",
      grid: { xs: 12, sm: 6 },
    },
    {
      name: "number",
      label: "Phone Number",
      type: "text",
      grid: { xs: 12, sm: 6 },
    },
    { name: "email", label: "Email", type: "text", grid: { xs: 12, sm: 6 } },
    {
      name: "description",
      label: "Description",
      type: "text",
      grid: { xs: 12 },
      multiline: true,
      rows: 4,
    },
    {
      name: "type",
      label: "Store type",
      type: "autocomplete",
      grid: { xs: 12, sm: 6 },
      options: storeTypes,
    },
    {
      name: "website",
      label: "Website",
      type: "text",
      grid: { xs: 12, sm: 6 },
    },
    {
      name: "customType",
      label: "Custom Store Type",
      type: "text",
      grid: { xs: 12, sm: 6 },
      isVisible: formData.type === "Other",
    },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update the formData state using setFormData
    setFormData({
      [name]: value,
    });
  };

  const handleAddClick = () => {
    const errors = validateLocationDetails(formData);
    if (Object.keys(errors).length === 0) {
      console.log(errors);
      console.log("good");
      onAdd();
    } else {
      console.log(errors);
      setErrors(errors);
    }
  };

  return {
    errors,
    fieldConfig,
    handleInputChange,
    handleAddClick,
  };
};

export default useAddLocationDialog;
