import MainLayout from "../../../../development/page/parts/layout/MainLayout/index";
import ProfilePageContainer from "../organisms/profilePageContainer";

const ProfilePage = () => {
  return (
    <MainLayout>
      <ProfilePageContainer />
    </MainLayout>
  );
};

export default ProfilePage;
