import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';

import { setInitialDashboardData } from '../../../features/initialData/initialDataSlice';
import { selectHasFetched } from '../../../features/initialData/initialDataSelector';
import { selectUserStatus } from '../../../features/user/userSelectors';

import { masterRequest } from '../../apiStructures/masterRequest';
import InitialLoadingPage from '../../../pages/loading/initialDataLoader';
import useFullFetchFeedback from '../../apiFetchers/fullPageLoaders';
import { setStatus } from "../../../features/user/userSlice";
import { setHasFetched } from "../../../features/initialData/initialDataSlice";
const MainRouteGuard = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const userStatus = useSelector(selectUserStatus);
  const fetched = useSelector(selectHasFetched);
  const fetchFeedback = useFullFetchFeedback();
  useEffect(() => {
    const initializeUser = async () => {
      if (!fetched) {
        setLoading(true);
        

        
       
          await fetchFeedback({
            endpoint: 'merchant/dashboard/data/',
            requestType: 'GET',
            onSuccess: (data) => {
        
              dispatch(setStatus(data.status));
              dispatch(setHasFetched(true));
              dispatch(setInitialDashboardData(data));
            },
            onError: (error) => {
              console.error('Error fetching dashboard data:', error);
            },
          });
 
  
        setLoading(false); // Ensure loading is set to false after completion
      }
    };
  
    initializeUser();
  }, [dispatch, fetched]);
  

  if (loading) {
    return <InitialLoadingPage/>; // Or a loading spinner
  }

  // Redirect based on user status




    if(fetched) {
      return <>{children}</>;
    }
  
 
};

export default MainRouteGuard;
