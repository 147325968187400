// src/features/dashboard/dashboardSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  transactions: '0',
  cashbackEarned: '0',
  cashbackRedeemed: '0',
  totalUsers: '0',
  duration: 30,
  monthlyTransactions: Array(12).fill(0),  // List for monthly transaction counts
  monthlyCashback: Array(12).fill(0)       // List for monthly cashback amounts
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateDashboardMetrics: (state, action) => {
      state.transactions = action.payload.transactions;
      state.cashbackEarned = action.payload.cashbackEarned;
      state.cashbackRedeemed = action.payload.cashbackRedeemed;
      state.totalUsers = action.payload.totalUsers;
      state.monthlyTransactions = action.payload.monthlyTransactions || state.monthlyTransactions;
      state.monthlyCashback = action.payload.monthlyCashback || state.monthlyCashback;
    },
    setDuration: (state, action) => {
      state.duration = action.payload;
    },
  },
});

export const { updateDashboardMetrics, setDuration } = dashboardSlice.actions;
export default dashboardSlice.reducer;
