import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import BankPage from "./pages/bank/combined/page/bankPage.js"
import ProfilePage from './pages/profile/combined/page/profilePage.js'
import RegisterPage from "./pages/register/combined/page/registerPage.js"
import SettingsPage from "./pages/settings/combined/page/settingPage.js";
import { Provider } from "react-redux";
import store from "./app/store";
import ReduxComponents from "./reduxComponents/main";
import DashboardPage from "./pages/dashboard/combined/page/dashboardPage.js";
import EmailPage from './pages/signUp/email/combined/page/emailPage.js';

import MainRouteGuard from "./functions/auth/routeGuards/mainRouteGuard.js";

import TransactionsPage from "./pages/transactions/combined/page/main.js";
import SignInPage from "./pages/signInUser/combined/page/AccountInfoPage.js";
import CashbackPage from "./pages/cashback/combined/page/cashbackPage.js";

import SetPageTitle from "./pageTitle.js";
import NotFoundCombined from "./pages/notFound/combined/page/main.js";
import './firebaseConfig';
import DevelopmentPageUI from "./development/page/main.js";
import DevelopmentComponentUI from "./development/component/main.js";
import ThemeCustomization from './themes/index.js';

import { JWTProvider } from "./contexts/JWTContext.js";
import { IntlProvider } from "react-intl";

// Import your translation files
import messages_en from './locales/en.json';
import messages_es from './locales/es.json'; // Example if you support Spanish
import KycPage from "./pages/kyc/ui/main.js";

const messages = {
  en: messages_en,
  es: messages_es,
};

// Get the user's preferred locale or use 'en' as default
const locale = navigator.language.split('-')[0] || 'en';

const App = () => {
  return (
    <IntlProvider locale={locale} messages={messages[locale] || messages['en']}>
      <JWTProvider>
        <Provider store={store}>
          <ThemeCustomization>
            <Router>
              <SetPageTitle />
              <ReduxComponents />
              <Routes>
                <Route
                  path="/bank"
                  element={
                    <MainRouteGuard>
                      <BankPage />
                    </MainRouteGuard>
                  }
                />
                <Route
                  path="/develpment/component"
                  element={<DevelopmentComponentUI />}
                />
                <Route path="/development/page" element={<DevelopmentPageUI />} />
                <Route
                  path="/cashback"
                  element={
                    <MainRouteGuard>
                      <CashbackPage />
                    </MainRouteGuard>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <MainRouteGuard>
                      <DashboardPage />
                    </MainRouteGuard>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <MainRouteGuard>
                      <ProfilePage />
                    </MainRouteGuard>
                  }
                />
                <Route
                  path="/dashboard/kyc"
                  element={
                    <MainRouteGuard>
                      <KycPage />
                      </MainRouteGuard>
                  }
                />
                <Route
                  path="/pos/register"
                  element={
                    <MainRouteGuard>
                      <RegisterPage />
                    </MainRouteGuard>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <MainRouteGuard>
                      <SettingsPage />
                    </MainRouteGuard>
                  }
                />
                <Route
                  path="/transactions"
                  element={
                    <MainRouteGuard>
                      <TransactionsPage />
                    </MainRouteGuard>
                  }
                />
              
                <Route
                  path="/sign-in"
                  element={<SignInPage />}
                />
                <Route
                  path="/email"
                  element={<EmailPage />}
                />
                <Route
                  path="*"
                  element={<NotFoundCombined />}
                />
              </Routes>
            </Router>
          </ThemeCustomization>
        </Provider>
      </JWTProvider>
    </IntlProvider>
  );
};
export default App;
