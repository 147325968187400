// AccountInfoPageUI.js
import React from "react";
import { Box } from "@mui/material";
import GeneralInput from "../../../../components/atoms/inputs/GeneralInput";
import PageLayout from "../../../../components/pageStructures/authPages/master";


const AccountInfoPageUI = ({
  accountInfo,
  setAccountInfo,
  error,

  children,
}) => {
  return (
    <>
      <Box sx={{ display: "grid", gap: 2 }}>
        <GeneralInput
          type={"text"}
          label="Email"
          name={"email"}
          value={accountInfo.email}
          onChange={(e) =>
            setAccountInfo({ ...accountInfo, email: e.target.value })
          }
          error={error?.email !== undefined && error?.email !== null}
          errorMessageAndhelp={error.email || ""}
        />
        <GeneralInput
          type={"password"} // Changed to "password" type for the password field
          label="Password"
          name={"password"}
          value={accountInfo.password}
          onChange={(e) =>
            setAccountInfo({ ...accountInfo, password: e.target.value })
          }
          error={!!error.password}
          errorMessageAndhelp={error.password || ""}
        />
      </Box>
      {children}
   </>
  );
};

export default AccountInfoPageUI;
