import ProfilePageUI from "../../ui/page/profilePageUI";
import ProfilePageLogic from "./../../functional/page/profilePageLogic";

const ProfilePageContainer = () => {
  const {
    fieldConfig,
    isAddLocationDialogOpen,
    isLocationDialogOpen,
    selectedLocation,
    storeLocations,
    activeInfoDailog,
    openUploadPhoto,
    state,
    errors,
    defaultStorePhoto,
    isConfirmDialogOpen,
    handleLocationClick,
    createLocation,
    setIsAddLocationDialogOpen,
    setIsLocationDialogOpen,
    handleConfirmDelete,
    handleInfoDailog,
    setFormData,
    Apisubmission,
    setSelectedPhoto,
    setUseDefaultPhoto,
    handleAddClick,
    handleInputChange,
    handleUseDefaultPhotoChange,
    handlePhotoChange,
    handleBackLocationForm,
    setIsConfirmDialogOpen,
   } = ProfilePageLogic()

  return (
      <ProfilePageUI
        fieldConfig={fieldConfig}
        errors={errors}
        openUploadPhoto={openUploadPhoto}
        state={state}
        storeLocations={storeLocations}
        isAddLocationDialogOpen={isAddLocationDialogOpen}
        isLocationDialogOpen={isLocationDialogOpen}
        selectedLocation={selectedLocation}
        activeInfoDailog={activeInfoDailog}
        defaultStorePhoto={defaultStorePhoto}
        isConfirmDialogOpen={isConfirmDialogOpen}
        setFormData={setFormData}
        Apisubmission={Apisubmission}
        setSelectedPhoto={setSelectedPhoto}
        setUseDefaultPhoto={setUseDefaultPhoto}
        handleLocationClick={handleLocationClick}
        createLocation={createLocation}
        setIsAddLocationDialogOpen={setIsAddLocationDialogOpen}
        setIsLocationDialogOpen={setIsLocationDialogOpen}
        handleConfirmDelete={handleConfirmDelete}
        handleInfoDailog={handleInfoDailog}
        handleAddClick={handleAddClick}   
        handleInputChange={handleInputChange}
        handleUseDefaultPhotoChange={handleUseDefaultPhotoChange}
        handlePhotoChange={handlePhotoChange}
        handleBackLocationForm={handleBackLocationForm}
        setIsConfirmDialogOpen={setIsConfirmDialogOpen}
      /> 
  )
};

export default ProfilePageContainer;
