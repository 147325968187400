import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import useFetchFeedback from "./../../../../functions/apiFetchers/fullPageLoaders";
import { updateMasterRegister, updateRegisterData } from "./../../../../features/register/registerSlice";
import { selectMasterRegister } from "./../../../../features/register/registerSelectors";
import RegisterSettingsLogic from './../molecules/registerSettingLogic';
import { handleInputChange } from "./../../../../functions/general/setData";

const RegisterLogic = () => {
    const masterRegister = useSelector(selectMasterRegister);
    const dispatch = useDispatch();
    const fetchFeedback = useFetchFeedback();
    const [accountInfo, setAccountInfo] = useState({
      username: "",
      password: "",
    });
    const [activeInfoDailog, setActiveInfoDailog] = useState(false)
    const [error, setError] = useState("");
    const [formVisible, setFormVisible] = useState(false);

    const handleFormVisible = () =>{
      setFormVisible(true);
    }
    const handleFormChange = (e) => {
      handleInputChange(e, setAccountInfo)
    }

    const {
      registerSettings,
      showUpdateDialog,
      handleSubmit,
      handleUpdateModal
    } = RegisterSettingsLogic()

    const handleSetupMasterRegisterSubmit = async (username, password) => {
        const errorValidation = [{ field: 'username', type: 'content', required: true}, { field: 'password', type: 'content', required: true}]
        
        await fetchFeedback({
        endpoint:`merchant/register-account/`,
        loaderType: "dialogueLoader",
        data: { username, password },
        requestType: "POST",
        validationRules: errorValidation,
        loaderMessage: "Updating registration...",
        successMessage: "Registration successful!",
        // setError, // function to handle validation errors
        onSuccess: (responseData) => {
          dispatch(updateRegisterData(responseData));
          dispatch(updateMasterRegister(true));
        
        },
        });
  };

  const handleSubmitForm = (username, password) => { 
    handleSubmit();
    setFormVisible(false);
    handleSetupMasterRegisterSubmit(username, password)
  }

  const handleInfoDailog = () => {
    setActiveInfoDailog(!activeInfoDailog)
  }

    return {
      activeInfoDailog,
      formVisible,
      masterRegister,
      registerSettings,
      showUpdateDialog,
      accountInfo,
      error,
      handleInfoDailog,
      handleUpdateModal,
      handleSubmitForm,
      handleFormVisible,
      handleSetupMasterRegisterSubmit,
      handleFormChange
    };
};

export default RegisterLogic;