import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';

const AnimatedMessages = () => {
  const messages = [
    "At Clavaa, we are transforming the way small businesses handle payments and customer retention.",
    "Our platform eliminates traditional payment processing fees and reinvests those savings into robust rewards programs.",
    "By leveraging our extensive network, businesses can offer compelling rewards that drive customer loyalty and long-term growth.",
    "Join us as we empower small businesses to compete with the capabilities of large corporations, fostering a thriving community of connected and successful enterprises.",
  ];

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    const messageTimer = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 6000); // Change message every 6 seconds

    return () => clearInterval(messageTimer);
  }, []);

  const variants = {
    enter: { opacity: 0, y: 20 },
    center: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={currentMessageIndex}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{ duration: 0.6, ease: 'easeInOut' }}
        style={{
          minHeight: '85px', // Fixed minimum height to prevent layout shift
          display: 'flex',
          alignItems: 'center', // Vertically center the message
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h4"
          component="div"
          sx={{
            fontWeight: 500,
            lineHeight: 1.7,
            color: 'white',
            textAlign: 'center',
            mx: 'auto',
            maxWidth: '600px',
            px: 2,
          }}
        >
          {messages[currentMessageIndex]}
        </Typography>
      </motion.div>
    </AnimatePresence>
  );
};

export default AnimatedMessages;
