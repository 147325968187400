import React from "react";
import GeneralInput from "../../../../components/atoms/inputs/GeneralInput";
import CustomInputDialog from "../../../../components/molecules/dialogues/customInput";

const RequestCodeDialogUI = ({ open, handleClose, email, error, setEmail, handleSubmit }) => {
  return (
    <CustomInputDialog
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title="Request Verification Link"
      subTitle="Enter your email to receive a link to reset your passowrd."
    >
      <GeneralInput
        type={"text"}
        label="Email Address"
        name={"email"}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={!!error.email}
        errorMessageAndhelp={error.email || ""}
      />
    </CustomInputDialog>
  );
};

export default RequestCodeDialogUI;
