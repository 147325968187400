import React from "react";
import AddLocationDialogUI from "../molecules/addLocationDialogUI";
import UploadStorePhotoDialogUI from "../molecules/uploadStorePhotoDialogUI";

const AddLocationUI = ({
  fieldConfig,
  onClose,
  openUploadPhoto,
  state,
  setFormData,
  Apisubmission,
  setSelectedPhoto,
  setUseDefaultPhoto,
  errors,
  handleAddClick,
  handleInputChange,
  defaultStorePhoto,
  handleUseDefaultPhotoChange,
  handlePhotoChange,
  handleBackLocationForm
}) => {
  return (
    <>
      {!openUploadPhoto ? (
        <AddLocationDialogUI
          open={true}
          onClose={onClose}
          formData={state}
          setFormData={setFormData}
          fieldConfig={fieldConfig}
          errors={errors}
          handleAddClick={handleAddClick}
          handleInputChange={handleInputChange}
        />
      ) : (
        <UploadStorePhotoDialogUI
          open={true}
          onClose={onClose}
          onSubmit={Apisubmission}
          onBack={() => handleBackLocationForm(false)}
          handlePhotoChange={handlePhotoChange}
          defaultStorePhoto={defaultStorePhoto}
          selectedPhoto={state.storePhoto}
          setSelectedPhoto={setSelectedPhoto}
          useDefaultPhoto={state.useDefaultPhoto}
          setUseDefaultPhoto={setUseDefaultPhoto}
          handleUseDefaultPhotoChange={handleUseDefaultPhotoChange}
        />
      )}
    </>
  );
};
export default AddLocationUI;
