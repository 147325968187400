
// src/functional/page/useEmail.js
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFullFetchFeedback from '../../../../../functions/apiFetchers/fullPageLoaders';

const useEmail = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({ email: '', password: '' });
  const [isCodeDialogOpen, setIsCodeDialogOpen] = useState(false);
  const [profilePk, setProfilePk] = useState(null);
  const navigate = useNavigate();
  const fetchFeedback = useFullFetchFeedback();

  // Handle email and password submission
  const handleSubmit = async (event) => {
    const errors = {};

    // Email validation
    if (!email) {
        errors.email = 'Email is required';
    } else {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            errors.email = 'Please enter a valid email address';
        }
    }

    // Password validation
    if (!password) {
        errors.password = 'Password is required';
    } else if (password.length < 12) {
        errors.password = 'Password must be at least 12 characters long';
    } else {
        const hasLetter = /[a-zA-Z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        if (!hasLetter || !hasNumber || !hasSymbol) {
            errors.password = 'Password must contain at least one letter, one number, and one symbol';
        }
    }

    // If there are any errors, set them and return to prevent form submission
    if (Object.keys(errors).length > 0) {
        setError(errors);
        return;
    }

    // Clear errors if validation passes
    setError({});


    const errorValidation = [
      { field: 'email', type: 'email', required: true },
     
    ];

    await fetchFeedback({
      endpoint: 'merchant/email/',
      requestType: 'POST',
      auth: false,
      data: { email},
      setError: setError,
      validationRules: errorValidation,
      loaderMessage: 'Verifying Email...',
      onSuccess: (responseData) => {
        console.log(responseData)
        setProfilePk(responseData.profile_pk);
        setIsCodeDialogOpen(true); // Open code verification dialog after email success
      },
      onError: (err) => {
        console.log(err)
        setError(err);
      },
    });
  };

  const handleCloseCodeDialog = () => {
    setIsCodeDialogOpen(false);
  };
  const title = 'Set Email and Password';
  const description = 'Your email and password secure your account and transactions.';
  
  
  const currentStep = 1;

  return {
    email,
    setEmail,
    password,
    setPassword,
    error,
    isCodeDialogOpen,
    handleCloseCodeDialog,
    handleSubmit,
    profilePk,
    setProfilePk,
    navigate,
    title,
    description,
    currentStep,
  };
};

export default useEmail;

