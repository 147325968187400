import React from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
} from "@mui/material";
import GeneralButton from "../../../../components/atoms/buttons/GeneralButton";

const UploadStorePhotoDialogUI = ({
  open,
  onClose,
  onSubmit,
  onBack,
  selectedPhoto,
  useDefaultPhoto,
  defaultStorePhoto,
  handlePhotoChange,
  handleUseDefaultPhotoChange,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Upload Store Photo</DialogTitle>
      <DialogContent>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            {/* Displaying the uploaded/default photo */}
            <img
              src={selectedPhoto || defaultStorePhoto}
              alt="Store"
              style={{ maxWidth: "100%", borderRadius: "4px" }}
            />
          </Grid>
          <Grid item>
            <GeneralButton
              label="Upload Photo"
              onClick={handlePhotoChange}
              type="upload-photo"
              center={true}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={useDefaultPhoto}
                  onChange={handleUseDefaultPhotoChange}
                />
              }
              label="Use a default photo for my store profile"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <GeneralButton
          label="Back"
          onClick={onBack}
          type="PlainText"
          center={true}
        />
        <GeneralButton
          label="Submit"
          onClick={() => onSubmit({ selectedPhoto, useDefaultPhoto })}
          type="PlainText"
          center={true}
        />
      </DialogActions>
    </Dialog>
  );
};

export default UploadStorePhotoDialogUI;
