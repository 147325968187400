import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDialog from "../../../../components/molecules/dialogues/confirm";
import GeneralButton from "../../../../components/atoms/buttons/GeneralButton";

const StoreLocationDailogUI = ({
  isOpen,
  onClose,
  location,
  handleConfirmDelete,
  isConfirmDialogOpen,
  setIsConfirmDialogOpen,
}) => {
      // Check if logo is Base64 by checking for the 'data:image' prefix
// Ensure store_photo exists and is a string before calling startsWith
const isBase64 = typeof location.store_photo === 'string' && location.store_photo.startsWith('data:image');

// Determine the correct source for the image (Base64, HTTP URL, or Base64-encoded string without prefix)
const logoSrc = isBase64
  ? location.store_photo // It's already a Base64 string with 'data:image' prefix
  : typeof location.store_photo === 'string' && location.store_photo.startsWith('http') // If it's an HTTP(S) URL, use it directly
  ? location.store_photo // It's a URL
  : location.store_photo // Assume it's a Base64-encoded string without the prefix
  ? `data:image/png;base64,${location.store_photo}` // Prepend the Base64 image prefix
  : ''; // Fallback in case store_photo is null or undefined

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
        <DialogTitle>
          {location.name}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {location.store_photo && (
          <img

          src={logoSrc}
            alt="Location"
            style={{
              maxWidth: "100%",
              height: "auto",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        )}
        <DialogContent>
          <Typography gutterBottom>Type: {location.type}</Typography>
          <Typography gutterBottom>Number: {location.number}</Typography>
          <Typography gutterBottom>Email: {location.email}</Typography>
          <Typography gutterBottom>Address: {location.address}</Typography>
          <Typography
            sx={{
              width: "90%",
              whiteSpace: "normal",
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
            }}
          >
            Description: {location.description}
          </Typography>
        </DialogContent>
        <DialogActions>
          <GeneralButton
            label="Delete"
            iconName={"DeleteIcon"}
            color="error"
            onClick={() => setIsConfirmDialogOpen(true)}
          />
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onConfirm={() => handleConfirmDelete(location.id)}
        title="Confirm Deletion"
        content="Are you sure you want to delete this location? This action cannot be undone."
      />
    </>
  );
};

export default StoreLocationDailogUI;
