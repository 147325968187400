import { Chip, ListItem, ListItemText } from "@mui/material";

const LocationDetailUI = ({ location, handleLocationClick }) => {
  console.log(location);
  return (
    <ListItem
      key={location.id}
      button
      onClick={() => handleLocationClick(location)}
      secondaryAction={
        <Chip
          sx={{ width: 130 }}
          variant="outlined"
          label={location.status === "true" || location.status === true ? "Status Verified" : "Status Pending"}
          size="small"
          color={location.status === "true" || location.status === true ? "success" : "warning"}
        />
      }
    >
      <ListItemText primary={location.name} secondary={location.address} />
    </ListItem>
  );
};

export default LocationDetailUI;
