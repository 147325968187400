import React from 'react';
import MiddleCardContent from '../../UI/organism/signInCard';
import UseSignIn from '../../Functional/molecule/useSignInCard';

const SignInContainer = ({
    currentStep,
    handleSubmit,
    title,
    description, // Added description prop
    onBack,
    backArrow,
    disabled,
    children // Accepting children for dynamic form or other content
}) => {
  // Use the functions provided by UseSignUp
  const {  navigateToSignUp } = UseSignIn();

  return (
    <MiddleCardContent
      
    navigateToSignUp={navigateToSignUp}
     
      
        handleSubmit={handleSubmit}
        title={title}
        description={description}
        onBack={onBack}
        backArrow={backArrow}
        disabled={disabled}
        children ={children}

    />
  );
};

export default SignInContainer;
