import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";

import GeneralButton from "../../../../components/atoms/buttons/GeneralButton";
import DynamicForm from "../../../../components/atoms/inputs/dynamic";

const AddLocationDialogUI = ({
  open,
  onClose,
  formData,
  fieldConfig,
  handleInputChange,
  errors,
  handleAddClick,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Add New Location</DialogTitle>
      <DialogContent>
        <DynamicForm
          fieldConfig={fieldConfig}
          formData={formData}
          handleInputChange={handleInputChange}
          errors={errors}
        />
      </DialogContent>
      <DialogActions>
        <GeneralButton
          label="Cancel"
          onClick={onClose}
          type="PlainText"
          center
        />
        <GeneralButton
          label="Next"
          onClick={handleAddClick}
          type="PlainText"
          center
        />
      </DialogActions>
    </Dialog>
  );
};

export default AddLocationDialogUI;
