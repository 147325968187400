import { useState } from "react";

const useManagerComponent = ({ createLocation }) => {
  const [state, setState] = useState({
    name: "",
    number: "",
    email: "",
    website: "",
    description: "",
    type: "",
    customType: "",
    address: {
      address: "",
      city: "",
      state: "",
      zip_code: "",
    },

    storePhoto: null,
    useDefaultPhoto: false,
    openUploadPhoto: false,
  });
  const [openUploadPhoto, setOpenUploadPhoto] = useState(false);

  const Apisubmission = () => {
    createLocation(state);
  };

  const setFormData = (newFormData) => {
    setState((prevState) => ({
      ...prevState,
      ...newFormData,
    }));
  };

  const setSelectedPhoto = (photo) => {
    setState((prevState) => ({
      ...prevState,
      storePhoto: photo,
    }));
  };

  const setUseDefaultPhoto = (value) => {
    setState((prevState) => ({
      ...prevState,
      useDefaultPhoto: value,
    }));
  };

  const updateOpenUploadPhoto = () => {
    setOpenUploadPhoto(true)
  }

  const handleBackLocationForm = () => {
    setOpenUploadPhoto(false)
  }

  return {
    state,
    openUploadPhoto,
    updateOpenUploadPhoto,
    setOpenUploadPhoto,
    Apisubmission,
    setFormData,
    setSelectedPhoto,
    setUseDefaultPhoto,
    handleBackLocationForm,
  };
};

export default useManagerComponent;
