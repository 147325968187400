const useUpdateStorePhotoDialog = ({
  setUseDefaultPhoto,
  setSelectedPhoto,
}) => {
  const defaultStorePhoto =
    "https://images.unsplash.com/photo-1565061830316-53e83aa760b8";
  const handlePhotoChange = (event) => {
    setUseDefaultPhoto(false);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedPhoto(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUseDefaultPhotoChange = (event) => {
    setUseDefaultPhoto(event.target.checked);
    if (event.target.checked) {
      setSelectedPhoto(defaultStorePhoto);
    }
  };

  return {
    handlePhotoChange,
    handleUseDefaultPhotoChange,
    defaultStorePhoto,
  };
};

export default useUpdateStorePhotoDialog;
