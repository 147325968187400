
import Box from "@mui/material/Box"; // Import Box component from MUI
import RegisterControlTab from "../molecules/registerControlTabUI";
import RefisterSettingUI from './../molecules/registerSettingUI';
import ChangeConfirmdailog from './../molecules/container/changeConfirmdailog';
import UpdateRegisterDailog from './../molecules/container/updateRegisterDailog'

const RegisterPage = ({
    activeInfoDailog,
    handleInfoDailog,
    formVisible,
    handleSetupMasterRegisterSubmit,
    masterRegister,
    registerSettings,
    accountInfo,
    error,
    handleSubmitForm,
    handleFormChange,
    showUpdateDialog,
    handleUpdateModal,
    handleFormVisible,
    }) => {
    return (
        <Box
            sx={{
            display: "flex",
            justifyContent: "center",
            margin: "auto",
            alignItems: "center",
            }}
        >
            {masterRegister ? (
            <RefisterSettingUI
                activeInfoDailog={activeInfoDailog}
                handleInfoDailog={handleInfoDailog}
                onClick={handleSetupMasterRegisterSubmit} 
                registerSettings={registerSettings} 
                handleUpdateModal={handleUpdateModal}
                showUpdateDialog={showUpdateDialog}
                handleSubmitForm={handleSubmitForm}
            />
            ) : (
            <RegisterControlTab
                activeInfoDailog={activeInfoDailog}
                handleInfoDailog={handleInfoDailog}
                accountInfo={accountInfo}
                error={error}
                handleFormChange={handleFormChange}
                onSignUp={handleSetupMasterRegisterSubmit}
            />
            )}
            {showUpdateDialog && (
                <ChangeConfirmdailog
                    formVisible={formVisible}
                    open={showUpdateDialog}
                    onClose={() => handleUpdateModal(false)}
                    handleFormVisible={handleFormVisible}
                />
            )}
            {formVisible && showUpdateDialog &&(
                <UpdateRegisterDailog
                    accountInfo={accountInfo}
                    error={error}
                    open={showUpdateDialog}
                    onClose={() => handleUpdateModal(false)}
                    onSubmit={handleSubmitForm}
                    handleFormChange={handleFormChange}
                />
            )}
        </Box>
    );
}
export default RegisterPage;